.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.w-65 {
    width: 65%;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-3 {
    margin-top: 3px;
}

.mr-10 {
    margin-right: 10px !important;
}

.d-flex {
    display: flex
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.maxwidth343 {
    max-width: 343px !important;
}

.maxwidth374 {
    max-width: 374px;
}

.maxwidth-240 {
    max-width: 240px ;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mb-1 {
    margin-bottom: 1px;
}

.MuiIconButton-root:hover{
    background-color: transparent !important;
}

.text-center {
    text-align: center;
}

.m-auto {
    margin: auto !important;
}

.pt-20{
    padding-top: 20px !important;
}

.p-15 {
    padding: 15px;
}

.m-5 {
    margin: 5px;
}

.fontSize-12 {
    font-size: 12px;
}

.fontSize-13 {
    font-size: 13px;
}

.fontSize-14 {
    font-size: 14px;
}

.fontSize-17 {
    font-size: 17px;
}

.fontSize-19 { 
    font-size: 19px;
}

.fw-500 {
    font-weight: 500;
}

.pointer {
    cursor: pointer;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.color-error {
    color: #e66161;
}