@import '../variables.scss';

.topbarContainer {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0px;
    height: 54px;
    border-bottom: 1.5px solid #C8C8C8;

}

.topbarActionContainer {
    width: 65%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: $small) {
        min-width: max-content;
    }
    @media screen and (max-width: $small) {
        min-width: 90%;
    }
}

.searchInput {
    font-size: 13px !important;
}

.searchbarContainer {
    width: max-content;
    max-width: 215px;
    background-color: #EFEFEF;
    border-radius: 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center
}

.signinBtn {
    width: 60px;
    height: 30px;
    border: 1px solid #3875EA !important;
    color: #3875EA !important;
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: normal !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    white-space: nowrap;
}

.signupBtn {
    width: 60px;
    height: 30px;
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: normal !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    white-space: nowrap;
}
