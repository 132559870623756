.next-btn {
    font-size: 15px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', 'Geneva', 'Verdana', 'sans-serif';
    width: 100%; 
    min-height: 40px;
    background-color: #3875EA;
    border-radius: 5px;
    color: white;
    border: none;
}

.confirmation-code-input {
    background-color: #FAFAFA;
    font-size: 19px !important;
    min-width: 253px;
    min-height: 43px;
    margin-top: 15px;
}

.confirmation-code-input input {
    padding: 12px;
    text-align: center !important;
}

.confirmation-code-input input::placeholder {
    font-size: 14px !important;
    text-align: center !important;
}

.re-send {
    color: #3875EA;
    font-size: 13px;
    text-decoration: underline;
}