.logoImg {
    width: 121px;
    height: 26px
}

.MuiFormControl-root {
    min-height: 43px !important;
    min-width: 343px !important;
}

.PrivateNotchedOutline-root-2 {
    border: none !important;
}

.MuiAutocomplete-input:first-child {
    font-size: 15px;
}

.phone-code-input {
    background-color: #FAFAFA;
    font-size: 19px !important;
    margin-right: 12px;
    min-width: 78px;
    min-height: 43px;
}

.phone-code-input input{
    padding: 10px;
}

.phoneNum-input {
    background-color: #FAFAFA;
    font-size: 19px !important;
    min-width: 253px;
    min-height: 43px;
}

.phoneNum-input input {
    padding: 12px;
}

.phoneNum-input input::placeholder {
    font-size: 14px !important;
}

.next-btn {
    font-size: 15px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', 'Geneva', 'Verdana', 'sans-serif';
    width: 100%; 
    min-height: 40px;
    background-color: #3875EA;
    border-radius: 5px;
    color: white;
    border: none;
}

.or-line {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #A1A1A1; 
    line-height: 0.1em;
    margin: 10px 0 20px;
    font-size: 15px; 
    font-weight: normal;
} 
 
.or-line span { 
    background:#fff; 
    padding:0 10px; 
    color: #A1A1A1;
}

.publisher-signup{
    color: #3875EA;
    font-size: 15px;
    text-decoration: underline;
}