.second-labels-container {
    min-width: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin-right: 30px;
}

.addCertif-container {
    width: 80px;
    height: 80px;
    margin-top: 35px;
    border: 1px solid #A1A1A1;
    align-self: flex-start;
}

.addCertif {
    background-color: transparent !important;
    box-shadow: none !important;
}

.MuiDialog-paperWidthXs {
    min-width: 400px;
}