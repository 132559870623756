.addAvatar-container {
    width: 78px;
    height: 78px;
    margin-top: 35px;
}

.addAvatar {
    background-color: #FAFAFA !important;
    box-shadow: none !important;
}

.name-input {
    background-color: #FAFAFA;
    font-size: 19px !important;
    min-width: 253px;
    min-height: 43px;
    margin-top: 15px;
}

.name-input input {
    padding: 12px;
    font-size: 14px;
}

.name-input input::placeholder {
    font-size: 14px !important;
}

.labels-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin-right: 30px;
}

.suggest-bar {
    min-width: 253px;
    min-height: 43px;
    box-shadow: 0px 4px 5px #8c727287;
    align-self: end;
    margin-top: 10px;
}

.suggest-choice {
    border-bottom: 0.5px solid #807d7d1a;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.avatar-preview {
    width: 78px;
    height: 78px;
    border-radius: 50px;
}